import { baseURL } from '../config/api.json'
import axios from 'axios'

const api = axios.create({

  // TODO: descobrir por que neste ponto process é undefined
  //baseURL: process.env.API_URL
  baseURL,
  //headers: {crossorigin: 'true'}
})

export default api
